const colors = {
  blue: {
    darkest: "#000f3a",
    darker: "#00144d",
    neutral: "#01256e",
    lighter: "#045ea7",
  },
  red: {
    darkest: "#57000a",
    darker: "#74000e",
    neutral: "#95001a",
  },
  white: "#ffffff",
  gray: {
    dark2: "#44464b",
  },
};

export default colors;
